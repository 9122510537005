<template>
    <v-card flat>
        <div class="ma-0 pa-0">
            <v-card-title>
                <div class="d-flex align-center">
                    <span class="secondary--text font-weight-bold mr-2">Object distribution</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon small v-on="on">info</v-icon>
                        </template>
                        <span>Object distribution allows you to selectively send files, tables, and applications across instances or spaces.</span>
                    </v-tooltip>
                </div>
            </v-card-title>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="8" offset-md="2">
                        <v-stepper v-model="stepperStep" vertical>
                            <v-stepper-step
                                :complete="stepperStep > 1"
                                :color="stepperStep === 1 ? 'primary' : 'secondary'"
                                :editable="stepperStep > 1"
                                :step="1"
                                class="rounded">
                                Review staged objects
                            </v-stepper-step>
                            <v-stepper-content step="1">
                                <div v-if="!isStagingEmpty && (applicationsAutoAdded || showAddReadMeHint)" class="d-flex flex-column align-start my-3 pl-3">
                                    <v-banner v-if="showAddReadMeHint" class="mb-4" width="100%">
                                        <v-icon slot="icon" large icon="info" color="info">info</v-icon>
                                        <div class="font-weight-bold secondary--text">Share README file?</div>
                                        A readme exists among your files but it is not staged.
                                        <template v-slot:actions>
                                            <v-btn @click="stageReadmeFile" small text color="primary">Stage readme</v-btn>
                                        </template>
                                    </v-banner>
                                    <v-alert
                                        class="mb-4"
                                        v-if="applicationsAutoAdded && stagingObjects.applications.length > 0"
                                        outlined
                                        prominent
                                        dense
                                        type="success">
                                        <div class="d-flex flex-column secondary--text" style="max-width: 800px">
                                            <span class="font-weight-bold subtitle-2">Recent apps auto-staged</span>
                                            <span class="subtitle-2">
                                                Your recent apps were automatically staged to allow you to share recently installed packages / added new
                                                applications.
                                            </span>
                                            <div class="d-flex align-center">
                                                <v-icon color="success" small>mdi-arrow-right</v-icon>
                                                <span class="caption font-weight-bold success--text">
                                                    Consider unstaging applications you don't want to share.
                                                </span>
                                            </div>
                                        </div>
                                    </v-alert>
                                    <v-alert v-if="totalFileSize > Math.pow(1024, 3)" text dense type="error">
                                        Warning: you're distributing considerable amount of data. Distribution can take up to several minutes!
                                    </v-alert>
                                    <v-alert v-if="totalAppSize > Math.pow(1024, 3)" text dense type="error">
                                        Warning: you're distributing many application files. Distribution can take up to several minutes!
                                    </v-alert>
                                </div>
                                <div class="d-flex flex-column align-start justify-start w-100" v-if="!isStagingEmpty">
                                    <v-expansion-panels v-model="stagedObjectsPanel" flat multiple>
                                        <v-expansion-panel v-if="stagingObjects.files.length">
                                            <v-expansion-panel-header class="px-0">
                                                <span class="secondary--text font-weight-medium">Files ({{ stagingObjects.files.length }})</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-btn
                                                                color="error"
                                                                text
                                                                :disabled="!stagingObjects.files.length"
                                                                @click="clearStaging('files')"
                                                                small>
                                                                <v-icon small>clear_all</v-icon>
                                                                Unstage all files
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                                <v-divider />
                                                <v-list class="pa-0" dense>
                                                    <v-list-item-group class="pa-0 ma-0" color="secondary">
                                                        <v-list-item
                                                            style="max-height: 5px"
                                                            v-for="file in orderedStagedObjects.files"
                                                            :key="file.fid"
                                                            class="pa-0">
                                                            <v-list-item-avatar>
                                                                <v-icon v-if="file.type === nuvolosObjectTypes.FOLDER || file.fileType === 2" small>
                                                                    folder
                                                                </v-icon>
                                                                <v-icon v-else small>mdi-file-outline</v-icon>
                                                            </v-list-item-avatar>

                                                            <v-list-item-content>
                                                                <v-list-item-title class="secondary--text subtitle-2">
                                                                    <v-container fluid>
                                                                        <v-row>
                                                                            <v-col cols="6">
                                                                                {{ file.filePath }}
                                                                            </v-col>
                                                                            <v-col cols="6">
                                                                                {{ humanFileSize(file.size) }}
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-list-item-title>
                                                            </v-list-item-content>

                                                            <v-list-item-action class="ma-0">
                                                                <v-btn icon @click="removeFromStaging([file], nuvolosObjectTypes.FILE)">
                                                                    <v-icon small color="error">cancel</v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </v-list-item-group>

                                                    <v-list-item class="pa-0 grey lighten-3" style="max-height: 5px">
                                                        <v-list-item-avatar width="40"></v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="secondary--text subtitle-2">
                                                                <v-container fluid>
                                                                    <v-row>
                                                                        <v-col cols="6"><b>Total size:</b></v-col>
                                                                        <v-col cols="6">
                                                                            <b>{{ humanFileSize(totalFileSize) }}</b>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action width="36"></v-list-item-action>
                                                    </v-list-item>
                                                </v-list>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-divider />
                                        <v-expansion-panel v-if="stagingObjects.tables.length">
                                            <v-expansion-panel-header class="px-0">
                                                <span class="secondary--text font-weight-medium">Tables ({{ orderedStagedObjects.tables.length }})</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-btn color="error" :disabled="!stagingObjects.tables.length" text @click="clearStaging('tables')" small>
                                                    <v-icon small>clear_all</v-icon>
                                                    Unstage all tables
                                                </v-btn>
                                                <v-divider />
                                                <v-list class="pa-0" dense>
                                                    <v-list-item-group class="pa-0 ma-0" color="secondary">
                                                        <v-list-item
                                                            style="max-height: 5px"
                                                            v-for="table in orderedStagedObjects.tables"
                                                            :key="table.tid"
                                                            class="pa-0">
                                                            <v-list-item-avatar>
                                                                <v-icon small>mdi-table-large</v-icon>
                                                            </v-list-item-avatar>

                                                            <v-list-item-content>
                                                                <v-list-item-title class="secondary--text subtitle-2">{{ table.filePath }}</v-list-item-title>
                                                            </v-list-item-content>

                                                            <v-list-item-action class="ma-0">
                                                                <v-btn icon @click="removeFromStaging([table], nuvolosObjectTypes.TABLE)">
                                                                    <v-icon small color="error">cancel</v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel v-if="stagingObjects.applications.length">
                                            <v-expansion-panel-header class="px-0">
                                                <span class="secondary--text font-weight-medium">
                                                    Applications ({{ orderedStagedObjects.applications.length }})
                                                </span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-btn
                                                                color="error"
                                                                :disabled="!stagingObjects.applications.length"
                                                                text
                                                                @click="clearStaging('applications')"
                                                                small>
                                                                <v-icon small>clear_all</v-icon>
                                                                Unstage all applications
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                                <v-divider />
                                                <v-list class="pa-0" dense>
                                                    <v-list-item-group class="pa-0 ma-0" color="secondary">
                                                        <v-list-item
                                                            style="max-height: 5px"
                                                            v-for="application in stagingObjects.applications"
                                                            :key="application.aid"
                                                            class="pa-0">
                                                            <v-list-item-avatar>
                                                                <v-icon small>desktop_windows</v-icon>
                                                            </v-list-item-avatar>

                                                            <v-list-item-content>
                                                                <v-list-item-title class="secondary--text subtitle-2">
                                                                    <v-container fluid>
                                                                        <v-row>
                                                                            <v-col cols="6">
                                                                                {{ application.filePath }}
                                                                            </v-col>
                                                                            <v-col cols="6">
                                                                                {{ humanFileSize(application.lib_usage || 0) }}
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-list-item-title>
                                                            </v-list-item-content>

                                                            <v-list-item-action class="ma-0">
                                                                <v-btn icon @click="removeFromStaging([application], nuvolosObjectTypes.APPLICATION)">
                                                                    <v-icon small color="error">cancel</v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                    <v-list-item class="pa-0 grey lighten-3" style="max-height: 5px">
                                                        <v-list-item-avatar width="40"></v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="secondary--text subtitle-2">
                                                                <v-container fluid>
                                                                    <v-row>
                                                                        <v-col cols="6"><b>Total size:</b></v-col>
                                                                        <v-col cols="6">
                                                                            <b>{{ humanFileSize(totalAppSize) }}</b>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action width="36"></v-list-item-action>
                                                    </v-list-item>
                                                </v-list>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </div>
                                <v-alert v-else text type="info" prominent max-width="600">
                                    <div class="title">Stage is empty</div>
                                    <div>In order to start a distribution operation, you must first stage objects such as files, tables, and applications.</div>

                                    <v-divider class="mt-4 mb-1 info" style="opacity: 0.22"></v-divider>

                                    <div class="d-flex align-center flex-wrap justify-end mt-2">
                                        <v-spacer></v-spacer>
                                        <v-btn color="info" outlined small @click="stageObjects(nuvolosObjectTypes.FILE)">stage files</v-btn>
                                        <v-btn
                                            v-if="!isTrialSpace"
                                            color="info"
                                            outlined
                                            small
                                            class="ml-1"
                                            @click="stageObjects(nuvolosObjectTypes.APPLICATION)">
                                            stage applications
                                        </v-btn>
                                        <v-btn color="info" outlined small class="ml-1" @click="stageObjects(nuvolosObjectTypes.TABLE)">stage tables</v-btn>
                                    </div>
                                </v-alert>
                                <div class="mt-5">
                                    <v-btn
                                        @click="isSpaceAdmin ? (stepperStep = 2) : (stepperStep = 3)"
                                        color="primary"
                                        :disabled="isStagingEmpty"
                                        e2e-staging-step-1>
                                        Continue
                                    </v-btn>
                                </div>
                            </v-stepper-content>
                            <v-stepper-step
                                :color="stepperStep === 2 ? 'primary' : 'secondary'"
                                :step="2"
                                :editable="isSpaceAdmin && stepperStep > 2"
                                :complete="stepperStep > 2"
                                class="rounded">
                                <div class="d-flex align-center">Save Bundle</div>
                                <small class="mt-1" v-if="stepperStep === 2">
                                    <template v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE">
                                        You can save the list of distributed objects as a named bundle for future reference, or you can also create assignments
                                        or a learning module from them.
                                    </template>
                                    <template v-else>You can save the list of distributed objects as a named bundle for future reference.</template>
                                </small>
                            </v-stepper-step>
                            <v-stepper-content step="2">
                                <v-alert v-if="bundleTab === 1 && totalFileSize > Math.pow(1024, 2) * 10" text dense type="error">
                                    Warning: any file selected as part of an assignment will be duplicated verbatim in every handin and handout. This can lead
                                    to exhaustion of storage space in the distributed instance, where the handin and handout data is stored. Please consider
                                    sharing data files in regular distributions with students, and only add the files to the assignment that need to be edited
                                    by the students.
                                </v-alert>
                                <v-list nav v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE">
                                    <v-list-item-group mandatory v-model="bundleTab" color="primary">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-list-item :value="0">
                                                    <v-list-item-content class="justify-center">
                                                        <v-icon>inventory</v-icon>
                                                        Distribution
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-list-item :value="1">
                                                    <v-list-item-content class="justify-center">
                                                        <v-icon>assignment</v-icon>
                                                        Assignment
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-group>
                                </v-list>

                                <v-tabs-items v-model="bundleTab" style="background: transparent">
                                    <v-tab-item>
                                        <v-form
                                            v-if="
                                                selectedDistributionReason === distributionSaveOptions.PUBLIC ||
                                                selectedDistributionReason === distributionSaveOptions.ADMIN_ONLY
                                            "
                                            v-model="validBundle"
                                            @submit.prevent>
                                            <v-container fluid>
                                                <v-row align="center" no-gutters>
                                                    <v-col cols="12" md="3">
                                                        <span class="subtitle-2">Bundle name</span>
                                                    </v-col>
                                                    <v-col cols="12" md="9">
                                                        <v-text-field
                                                            ref="bundleNameInput"
                                                            autofocus
                                                            v-model="bundleName"
                                                            :rules="[rules.required]"
                                                            required
                                                            outlined></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-form
                                            v-if="
                                                currentSpaceType === spaceTypes.EDUCATION_SPACE &&
                                                (selectedDistributionReason === distributionSaveOptions.PUBLIC ||
                                                    selectedDistributionReason === distributionSaveOptions.ADMIN_ONLY)
                                            "
                                            v-model="validBundle">
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col>
                                                        <v-card v-if="stagingObjects.tables.length > 0" elevation="5">
                                                            <v-alert color="error" border="left" colored-border icon="error" class="ma-0 secondary--text">
                                                                <div class="font-weight-bold">Assigments can't have tables</div>
                                                                <div class="subtitle-2">
                                                                    You can not create assignments because you have staged some tables, please unstage tables to
                                                                    create an assignment.
                                                                </div>
                                                            </v-alert>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                                <v-row align="center" no-gutters>
                                                    <v-col cols="12" md="3">
                                                        <span class="subtitle-2">Assignment name</span>
                                                    </v-col>
                                                    <v-col cols="12" md="9">
                                                        <v-text-field
                                                            ref="bundleNameInput"
                                                            autofocus
                                                            v-model="bundleName"
                                                            :rules="[rules.required]"
                                                            required
                                                            outlined></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="selectedDistributionReason === distributionSaveOptions.PUBLIC" align="center" no-gutters>
                                                    <v-col cols="12" md="3"><span class="subtitle-2">Assignment description</span></v-col>
                                                    <v-col cols="12" md="9">
                                                        <v-text-field v-model="assignmentDescription" outlined></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="selectedDistributionReason === distributionSaveOptions.PUBLIC" align="center" no-gutters>
                                                    <v-col cols="12" md="3"><span class="subtitle-2">Hand-in deadline</span></v-col>
                                                    <v-col cols="12" md="9">
                                                        <div class="d-flex flex-column">
                                                            <v-menu
                                                                v-model="bundleDateMenu"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="bundleDeadlineDate"
                                                                        readonly
                                                                        label="Date"
                                                                        :rules="[rules.required]"
                                                                        required
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        outlined></v-text-field>
                                                                </template>
                                                                <v-date-picker v-model="bundleDeadlineDate" @input="bundleDateMenu = false"></v-date-picker>
                                                            </v-menu>
                                                            <v-menu
                                                                v-model="bundleTimeMenu"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                offset-y
                                                                max-width="310px"
                                                                min-width="310px">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="bundleDeadlineTime"
                                                                        label="Time"
                                                                        readonly
                                                                        :rules="[rules.required]"
                                                                        required
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        outlined></v-text-field>
                                                                </template>
                                                                <div class="px-2">
                                                                    <time-picker v-if="bundleTimeMenu" v-model="bundleDeadlineTime" format="24hr"></time-picker>
                                                                </div>
                                                            </v-menu>
                                                            <div v-if="bundleHandinDatetime" class="d-flex">
                                                                <span>Displayed deadline:</span>
                                                                <span class="font-weight-medium ml-1">{{ bundleHandinDatetime | dateTimeToHuman }}</span>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-tab-item>
                                </v-tabs-items>

                                <div class="d-flex align-center mt-5">
                                    <v-btn color="primary" @click="moveToStepThree" :loading="creatingBundle" :disabled="disabledStepThree" e2e-staging-step-2>
                                        Continue
                                    </v-btn>
                                    <v-btn :disabled="loading || creatingBundle" color="secondary" text @click="stepperStep = 1" class="ml-2">Back</v-btn>
                                </div>
                            </v-stepper-content>
                            <v-stepper-step
                                :color="stepperStep === 3 ? 'primary' : 'secondary'"
                                :editable="!bundleTab && stepperStep > 3"
                                :complete="stepperStep > 3"
                                :step="3"
                                class="rounded">
                                Select Target
                            </v-stepper-step>
                            <v-stepper-content step="3">
                                <div class="d-flex flex-column pl-3">
                                    <v-select
                                        :items="orgList"
                                        v-model="selectedOrg"
                                        @change="getOrgData($event)"
                                        item-text="long_id"
                                        item-value="oid"
                                        label="Select Organization"
                                        hint="Target organization"
                                        persistent-hint
                                        single-line
                                        dense
                                        outlined
                                        class="mb-3"
                                        :disabled="fetchingOrgData || fetchingSpaceData">
                                        <template v-slot:prepend-item>
                                            <v-text-field autofocus v-model="orgSearch" class="px-4" label="Filter" outlined dense></v-text-field>
                                        </template>
                                    </v-select>
                                    <v-select
                                        v-if="selectedOrg && selectedOrgSpaces.length"
                                        :items="spaceList"
                                        v-model="selectedSpace"
                                        @change="getSpaceData($event)"
                                        item-text="long_id"
                                        item-value="sid"
                                        hint="Target space"
                                        persistent-hint
                                        :label="fetchingOrgData ? 'fetching spaces' : 'Select Space'"
                                        single-line
                                        dense
                                        outlined
                                        :disabled="fetchingOrgData || fetchingSpaceData"
                                        e2e-snapshot-select-space>
                                        <template v-slot:prepend-item>
                                            <v-text-field autofocus v-model="spaceSearch" class="px-4" label="Filter" outlined dense></v-text-field>
                                        </template>
                                    </v-select>
                                    <v-alert
                                        v-else-if="selectedOrg && !fetchingOrgData && !selectedOrgSpaces.length"
                                        prominent
                                        text
                                        type="warning"
                                        max-width="600">
                                        You don't have an editor role in any of the spaces of the selected organization.
                                    </v-alert>

                                    <div v-if="selectedSpace && !fetchingOrgData && !fetchingSpaceData">
                                        <v-radio-group v-model="allOrSpecificInstances">
                                            <v-radio
                                                :disabled="
                                                    !isMasterInstance ||
                                                    !isEditorOfDistributed ||
                                                    targetSpaceType !== spaceTypes.EDUCATION_SPACE ||
                                                    !selectedSpace ||
                                                    selectedSpace.toString() !== $route.params.sid.toString()
                                                "
                                                :label="shareWithAllLabel"
                                                value="shareWithAllInstances"></v-radio>
                                            <v-radio
                                                :disabled="!selectedSpaceAvailableTargetInstances.length"
                                                :label="shareWithOneOrSomeLabel"
                                                value="shareWithSpecificInstances"></v-radio>
                                        </v-radio-group>
                                    </div>
                                    <div v-if="showInstancesTable">
                                        <v-data-table
                                            v-model="selectedInstances"
                                            show-select
                                            :headers="headers"
                                            :items="instanceFetching ? [] : selectedSpaceAvailableTargetInstances"
                                            :single-select="isDevelopment"
                                            :items-per-page="-1"
                                            :search="instanceSearch"
                                            item-key="iid"
                                            :loading="fetchingSpaceData">
                                            <template v-slot:top>
                                                <v-alert
                                                    v-if="
                                                        isEditorOfDistributed &&
                                                        targetSpaceType === spaceTypes.EDUCATION_SPACE &&
                                                        selectedSpace &&
                                                        selectedSpace.toString() === $route.params.sid.toString()
                                                    "
                                                    text
                                                    type="warning">
                                                    <div class="d-flex flex-column" style="max-width: 800px">
                                                        <span class="font-weight-bold">Limited distribution</span>
                                                        <span>
                                                            With this option, only the selected student
                                                            <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE && isDevelopment">instance</span>
                                                            <span v-else-if="currentSpaceType === spaceTypes.EDUCATION_SPACE && !isDevelopment">instances</span>
                                                            will receive your objects. If you want all student instances to receive the same contents, select
                                                            <span class="font-weight-bold">{{ shareWithAllLabel }}</span>
                                                        </span>
                                                    </div>
                                                </v-alert>
                                                <v-text-field
                                                    v-model="instanceSearch"
                                                    append-icon="mdi-magnify"
                                                    label="Search"
                                                    single-line
                                                    hide-details
                                                    autofocus
                                                    outlined
                                                    dense></v-text-field>
                                            </template>
                                            <template v-slot:[`item.long_id`]="{ item }">{{ item.long_id }}</template>
                                            <template v-slot:[`item.description`]="{ item }">{{ item.description }}</template>
                                        </v-data-table>
                                    </div>
                                    <div
                                        v-if="
                                            selectedSpace &&
                                            !fetchingSpaceData &&
                                            !selectedSpaceAvailableTargetInstances.length &&
                                            isEditorOfDistributed &&
                                            targetSpaceType === spaceTypes.EDUCATION_SPACE
                                        "
                                        no-gutters
                                        class="d-flex">
                                        <v-alert prominent outlined type="success">
                                            <div class="d-flex flex-column" style="max-width: 800px">
                                                <span class="subtitle-1 ma-0 font-weight-bold">Distributing before inviting</span>
                                                <span class="ma-0">
                                                    Because you haven't invited any students yet, shared material will be available to new students after you
                                                    have invited them.
                                                </span>
                                            </div>
                                        </v-alert>
                                    </div>
                                    <div
                                        v-else-if="selectedSpace && !fetchingSpaceData && !selectedSpaceAvailableTargetInstances.length"
                                        no-gutters
                                        class="d-flex">
                                        <v-alert prominent text type="warning" max-width="600">
                                            <p class="subtitle-1 ma-0 font-weight-bold">No targets available</p>
                                            <ul>
                                                <li v-if="!isEditorOfDistributed">You have insufficient privileges to distribute to everyone.</li>
                                                <li v-if="!isSpaceAdmin">
                                                    There are no instances you are an editor of in this
                                                    <span v-if="targetSpaceType === spaceTypes.EDUCATION_SPACE">course</span>
                                                    <span v-else-if="targetSpaceType === spaceTypes.RESEARCH_SPACE">research project</span>
                                                    <span v-else>space</span>
                                                    .
                                                </li>
                                                <li v-else>There are no target instances to distribute to in this space</li>
                                            </ul>
                                        </v-alert>
                                    </div>
                                </div>
                                <div class="d-flex align-center">
                                    <v-btn
                                        @click="setSelectedSpaceTableStatus"
                                        color="primary"
                                        :loading="fetchingOrgData || fetchingSpaceData"
                                        :disabled="disableContinueInstanceSelection"
                                        e2e-staging-step-3>
                                        Continue
                                    </v-btn>
                                    <v-btn :disabled="loading" color="secondary" text @click="stepperStep = isSpaceAdmin ? 2 : 1" class="ml-2">Back</v-btn>
                                </div>
                            </v-stepper-content>
                            <v-stepper-step
                                :color="stepperStep === 4 ? 'primary' : 'secondary'"
                                :editable="stepperStep > 4"
                                :complete="stepperStep > 4"
                                :step="4"
                                class="rounded">
                                Notification
                            </v-stepper-step>
                            <v-stepper-content step="4">
                                <v-container fluid>
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-checkbox class="mt-0" v-model="notifyTargetUsers" label="Notify recipients of this bundle"></v-checkbox>
                                            <v-textarea autofocus v-if="notifyTargetUsers" v-model="notificationText" rows="2" auto-grow outlined>
                                                <template v-slot:label>Your message (optional)</template>
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col class="align-center">
                                            <v-btn @click="stepperStep = 5" color="primary" e2e-staging-step-4>Continue</v-btn>
                                            <v-btn color="secondary" text @click="stepperStep = bundleTab ? 2 : 3" class="ml-2">Back</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-stepper-content>

                            <v-stepper-step
                                :color="stepperStep === 5 ? 'primary' : 'secondary'"
                                :editable="stepperStep > 5"
                                :complete="stepperStep > 5"
                                :step="5"
                                class="rounded">
                                Select Distribution Mode
                            </v-stepper-step>
                            <v-stepper-content step="5">
                                <div class="d-flex flex-column pl-3">
                                    <v-list nav>
                                        <v-list-item-group
                                            mandatory
                                            class="mt-5"
                                            v-model="distributionStrategy"
                                            :color="customDistribution ? 'secondary' : 'primary'">
                                            <v-row justify="center">
                                                <v-col cols="12" md="4">
                                                    <v-list-item :value="availableDistributionStrategyOptions[0].backendCode" :disabled="customDistribution">
                                                        <v-list-item-content>
                                                            <div class="d-flex flex-column align-center justify-center">
                                                                <div>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon color="secondary" v-on="on" class="mr-1" small>
                                                                                mdi-information-outline
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>
                                                                            Distribute all your objects, and if a similar object exists in the target instance
                                                                            then it will be overwritten.
                                                                        </span>
                                                                    </v-tooltip>
                                                                    <span class="text-uppercase">overwrite</span>
                                                                </div>
                                                                <img width="100%" src="@/assets/overwrite.svg" />
                                                            </div>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-list-item :value="availableDistributionStrategyOptions[1].backendCode" :disabled="customDistribution">
                                                        <v-list-item-content>
                                                            <div class="d-flex flex-column align-center justify-center">
                                                                <div>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon color="secondary" v-on="on" class="mr-1" small>
                                                                                mdi-information-outline
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>
                                                                            Among the objects you are distributing, only those that do not exist in the target
                                                                            instances will be shared.
                                                                        </span>
                                                                    </v-tooltip>
                                                                    <span class="text-uppercase">distribute extra</span>
                                                                </div>
                                                                <img width="100%" src="@/assets/distribute_extra.svg" />
                                                            </div>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-list-item
                                                        :value="availableDistributionStrategyOptions[2].backendCode"
                                                        class="pa-0 ma-0"
                                                        :disabled="customDistribution">
                                                        <v-list-item-content>
                                                            <div class="d-flex flex-column align-center justify-center">
                                                                <div>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon color="secondary" v-on="on" class="mr-1" small>
                                                                                mdi-information-outline
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>
                                                                            Erease all objects in the target instance, and replace them with the objects that
                                                                            you are sharing.
                                                                        </span>
                                                                    </v-tooltip>
                                                                    <span class="text-uppercase">clear target and replace</span>
                                                                </div>
                                                                <img width="100%" src="@/assets/clear_target.svg" />
                                                            </div>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-group>
                                    </v-list>
                                    <v-row align="center">
                                        <v-col cols="12">
                                            <v-checkbox v-model="snapshotTargetBeforeDistributing" label="Snapshot target before distributing"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-alert v-if="snapshotTargetBeforeDistributing" type="success" outlined prominent class="mt-5">
                                        <div class="d-flex flex-column">
                                            <span class="ma-0 font-weight-bold">Backup Snapshot</span>
                                            <span>
                                                A snapshot of the current state of each target instance will be taken. This will allow users to recover or
                                                restore overwritten files.
                                            </span>
                                        </div>
                                    </v-alert>
                                    <v-alert
                                        v-else-if="!snapshotTargetBeforeDistributing && targetSpaceTablesAreEnabled"
                                        type="warning"
                                        text
                                        prominent
                                        class="mt-5">
                                        <div class="d-flex flex-column">
                                            <span class="ma-0 font-weight-bold">Backup Snapshot</span>
                                            <span>
                                                Since tables are enabled in the target space, target instance(s) will
                                                <span class="font-weight-bold">not</span>
                                                be snapshotted before distribution for increased performance. This is recommended only for distributing
                                                completely new material to avoid unrecoverable data loss in the target(s). Snapshotting still can be turned on,
                                                however distribution will be significantly slower.
                                            </span>
                                        </div>
                                    </v-alert>
                                    <v-alert
                                        v-else-if="!snapshotTargetBeforeDistributing && !targetSpaceTablesAreEnabled"
                                        type="warning"
                                        text
                                        prominent
                                        class="mt-5">
                                        <div class="d-flex flex-column" style="max-width: 800px">
                                            <span class="ma-0 font-weight-bold">Backup Snapshot</span>
                                            <span>
                                                Target instance(s) will
                                                <span class="font-weight-bold">not</span>
                                                be snapshotted before distribution.
                                            </span>
                                        </div>
                                    </v-alert>
                                    <v-switch v-model="customDistribution">
                                        <template v-slot:label>
                                            <span :class="customDistribution ? 'subtitle-1 font-weight-bold secondary--text' : 'subtitle-1'">
                                                Custom Distribution
                                            </span>
                                        </template>
                                    </v-switch>
                                    <div>
                                        <div v-if="customDistribution">
                                            <v-row align="center" v-if="selectedObjectTypes.includes(userWorkAreas.WORKSPACE)">
                                                <v-col cols="12" md="3">Workspace files</v-col>
                                                <v-col cols="8">
                                                    <v-select
                                                        v-model="workspaceDistMode"
                                                        :items="availableDistributionStrategyOptions"
                                                        class="fileSelect"
                                                        persistent-hint
                                                        item-text="text"
                                                        item-value="backendCode"
                                                        single-line
                                                        outlined></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" v-if="selectedObjectTypes.includes(userWorkAreas.PERSONAL)">
                                                <v-col cols="12" md="3">Personal files</v-col>
                                                <v-col cols="8">
                                                    <v-select
                                                        v-model="personalFilesDistMode"
                                                        :items="availableDistributionStrategyOptions"
                                                        item-text="text"
                                                        item-value="backendCode"
                                                        class="fileSelect"
                                                        outlined></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" v-if="selectedObjectTypes.includes(nuvolosObjectTypes.TABLE)">
                                                <v-col sm="12" md="3">Tables</v-col>
                                                <v-col cols="8">
                                                    <v-select
                                                        v-model="tablesDistMode"
                                                        :items="availableDistributionStrategyOptions"
                                                        item-text="text"
                                                        item-value="backendCode"
                                                        class="fileSelect"
                                                        outlined></v-select>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="d-flex align-center mt-5">
                                            <v-btn @click="stepperStep = 6" color="primary" e2e-staging-step-5>Continue</v-btn>
                                            <v-btn :disabled="loading" color="secondary" text @click="stepperStep = 4" class="ml-2">Back</v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-stepper-content>
                            <v-stepper-step color="primary" :step="6" class="rounded">Overview</v-stepper-step>
                            <v-stepper-content :step="6">
                                <div class="pl-3">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12">
                                                <h3 class="text-caption text-uppercase">{{ bundleTab ? 'Assignment' : 'Bundle' }} name:</h3>
                                            </v-col>
                                            <v-col>{{ bundleName }}</v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <h3 class="text-caption text-uppercase">Target:</h3>
                                            </v-col>
                                            <v-col>
                                                <div>
                                                    {{ getSelectedOrgName }}
                                                    <v-icon>keyboard_arrow_right</v-icon>
                                                    {{ selectedSpaceName }}
                                                    <v-icon>keyboard_arrow_right</v-icon>
                                                    {{ getSelectedSharedwithInstanceLabels }}
                                                </div>
                                                <div>
                                                    <v-chip class="ml-2" v-if="bundleTab">
                                                        <v-icon class="mr-2">mdi-alarm</v-icon>
                                                        {{ bundleDeadlineDate }} {{ bundleDeadlineTime }}
                                                    </v-chip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="assignmentDescription.length > 0 || notificationText.length > 0">
                                            <v-col cols="12">
                                                <h3 class="text-caption text-uppercase">
                                                    {{ bundleTab ? 'Assignment description' : 'Notification for target users' }}:
                                                </h3>
                                            </v-col>
                                            <v-col>
                                                <div>{{ bundleTab ? assignmentDescription : notificationText }}</div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12"><h3 class="text-caption text-uppercase">Bundled objects:</h3></v-col>
                                            <v-col>
                                                <v-card elevation="5">
                                                    <v-list-item>
                                                        <v-list-item-icon>
                                                            <v-icon>folder</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>Files</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="orderedStagedObjects.files.length === 0" class="justify-center">
                                                        <div class="d-flex flex-column py-5 opacity-3">
                                                            <v-icon>mdi-dots-square</v-icon>
                                                            <span>Empty</span>
                                                        </div>
                                                    </v-list-item>
                                                    <template v-for="(file, i) in orderedStagedObjects.files">
                                                        <v-list-item :key="file.fid">
                                                            <v-list-item-icon>
                                                                <v-icon v-if="file.type === nuvolosObjectTypes.FOLDER || file.fileType === 2" small>
                                                                    folder
                                                                </v-icon>
                                                                <v-icon v-else small>mdi-file-outline</v-icon>
                                                            </v-list-item-icon>
                                                            {{ file.filePath }}
                                                        </v-list-item>
                                                        <v-divider v-if="i < orderedStagedObjects.files.length - 1" :key="'d' + file.fid"></v-divider>
                                                    </template>
                                                </v-card>
                                            </v-col>
                                            <v-col>
                                                <v-card elevation="5">
                                                    <v-list-item>
                                                        <v-list-item-icon>
                                                            <v-icon>desktop_windows</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>Apps</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="orderedStagedObjects.applications.length === 0" class="justify-center">
                                                        <div class="d-flex flex-column py-5 opacity-3">
                                                            <v-icon>mdi-dots-square</v-icon>
                                                            <span>Empty</span>
                                                        </div>
                                                    </v-list-item>
                                                    <template v-for="(app, i) in orderedStagedObjects.applications">
                                                        <v-list-item :key="app.aid">
                                                            <v-list-item-icon>
                                                                <v-icon small>desktop_windows</v-icon>
                                                            </v-list-item-icon>
                                                            {{ app.filePath }}
                                                        </v-list-item>
                                                        <v-divider v-if="i < orderedStagedObjects.applications.length - 1" :key="'d' + app.aid"></v-divider>
                                                    </template>
                                                </v-card>
                                            </v-col>
                                            <v-col>
                                                <v-card elevation="5">
                                                    <v-list-item>
                                                        <v-list-item-icon>
                                                            <v-icon>mdi-table-large</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>Tables</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="orderedStagedObjects.tables.length === 0" class="justify-center">
                                                        <div class="d-flex flex-column py-5 opacity-3">
                                                            <v-icon>mdi-dots-square</v-icon>
                                                            <span>Empty</span>
                                                        </div>
                                                    </v-list-item>
                                                    <template v-for="(table, i) in orderedStagedObjects.tables">
                                                        <v-list-item :key="table.tid">
                                                            <v-list-item-icon>
                                                                <v-icon small>mdi-table-large</v-icon>
                                                            </v-list-item-icon>
                                                            {{ table.filePath }}
                                                        </v-list-item>
                                                        <v-divider v-if="i < orderedStagedObjects.tables.length - 1" :key="'d' + table.tid"></v-divider>
                                                    </template>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <v-row class="align-center">
                                            <v-col>
                                                <v-btn color="primary" :loading="loading" @click="distribute" e2e-staging-step-6>Share objects</v-btn>
                                                <v-btn :disabled="loading" color="secondary" text @click="stepperStep = 5" class="ml-2">Back</v-btn>
                                                <v-alert :value="error" type="error" icon="warning" class="mt-3" outlined>{{ errorContent }}</v-alert>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-stepper-content>
                        </v-stepper>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import regionsMixin from '@/mixins/regions'
import { sortArray, isEditorRole, isDistributedInstance, makeShortId, humanFileSize } from '@/utils'
import { invitationsAndRequests } from '@/mixins/invitationsAndRequests'
import { find, sortBy } from 'lodash'
import TimePicker from '@/components/TimePicker.vue'

const distributionStrategyOptions = {
    OVERWRITE: { text: 'Distribute all objects (overwrite target)', backendCode: 'OVERWRITE' },
    IGNORE_EXISTING: { text: 'Distribute extra objects compared to target', backendCode: 'IGNORE_EXISTING' },
    MIRROR: { text: 'Clean and replace target', backendCode: 'MIRROR' },
    SKIP: { text: 'Skip', backendCode: 'SKIP' }
}
export default {
    mixins: [enumsData, regionsMixin, invitationsAndRequests],
    components: {
        TimePicker
    },
    data() {
        return {
            selectedOrg: '',
            selectedSpace: '',
            bundleName: '',
            assignmentName: '',
            applicationsAutoAdded: false,
            assignmentDescription: '',
            bundleDateMenu: false,
            bundleDeadlineDate: '',
            bundleTimeMenu: false,
            bundleDeadlineTime: '',
            currentStep: 1,
            selectedInstances: [],
            spaceSnapshotData: [],
            selectedSpaceInstances: [],
            targetDistributedInstance: [],
            selectedOrgSpaces: [],
            stepperStep: 1,
            fetchingOrgData: false,
            creatingBundle: false,
            instanceFetching: false,
            error: false,
            spaceSearch: '',
            orgSearch: '',
            customDistribution: false,
            errorContent: 'An Error has occurred',
            addBundle: false,
            inputError: false,
            instanceSearch: '',
            notifyTargetUsers: true,
            headers: [
                {
                    text: 'Instance Name',
                    align: 'left',
                    value: 'long_id',
                    width: '20%'
                },
                { text: 'Description', align: 'left', value: 'description' }
            ],
            loading: false,
            workspaceFilesOnly: true,
            shareWithAll: true,
            allOrSpecificInstances: '',
            selectedShare: '',
            fetchingSpaceData: false,
            snapshotTargetBeforeDistributing: false,
            targetSpaceTablesAreEnabled: false,
            validBundle: false,
            notificationText: '',
            rules: {
                required: value => !!value || 'Required.'
            },
            distributionStrategy: distributionStrategyOptions.OVERWRITE.backendCode,
            workspaceDistMode: distributionStrategyOptions.OVERWRITE.backendCode,
            personalFilesDistMode: distributionStrategyOptions.OVERWRITE.backendCode,
            tablesDistMode: distributionStrategyOptions.OVERWRITE.backendCode,
            distributionSaveOptions: {
                PUBLIC: 'Public bundle',
                ADMIN_ONLY: 'Admin-only bundle',
                NO_BUNDLE: 'Do not create a bundle'
            },
            selectedDistributionReason: 'Public bundle',
            stagedObjectsPanel: [0, 1, 2],
            distTargetType: {
                SHARE_WITH_ALL_INSTANCES: 'shareWithAllInstances',
                SHARE_WITH_SPECIFIC_INSTANCES: 'shareWithSpecificInstances'
            },
            bundleTab: 0
        }
    },
    computed: {
        ...mapState('snapshotStore', ['stagingObjects', 'applications', 'fileAreaType', 'currentFiles']),
        ...mapGetters('spaceStore', ['currentSpaceType', 'currentSpaceData', 'isSpaceAdmin', 'isTrialSpace']),
        ...mapState(['userOrgs', 'bundleCreated']),
        ...mapGetters('snapshotStore', ['currentFileLocalPath', 'isDevelopment', 'isStagingEmpty']),
        ...mapState('orgStore', ['spacesWithPrimarySnapshots']),
        ...mapState('appStore', ['recentApps']),
        ...mapGetters('instanceStore', ['isMasterInstance']),
        showAddReadMeHint() {
            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE && !this.isStagingEmpty && this.isDevelopment) {
                const stagedReadme = this.stagingObjects.files.find(
                    file =>
                        file.short_id &&
                        file.short_id.toLowerCase() === 'readme.md' &&
                        file.local_path === '' &&
                        file.area === this.folderTypesLabels.WORKSPACE_FILES
                )
                const readmeFile = this.currentFiles.find(
                    file =>
                        file.short_id &&
                        file.short_id.toLowerCase() === 'readme.md' &&
                        file.local_path === '' &&
                        file.area === this.folderTypesLabels.WORKSPACE_FILES
                )
                if (!stagedReadme && readmeFile) {
                    return true
                }
            }
            return false
        },
        selectedSpaceAvailableTargetInstances() {
            if (this.selectedSpaceInstances.length) {
                return this.selectedSpaceInstances.filter(
                    instance =>
                        !isDistributedInstance(instance.long_id) &&
                        isEditorRole(instance.role) &&
                        !(this.isDevelopment && this.$route.params.iid.toString() === instance.iid.toString())
                )
            }
            return []
        },
        selectedSpaceName() {
            if (this.selectedSpace) {
                return this.selectedOrgSpaces.find(space => space.sid.toString() === this.selectedSpace.toString()).long_id
            }
            return ''
        },
        selectedDistributionStrategy() {
            if (this.customDistribution) {
                return 'Custom Distribution'
            }
            return distributionStrategyOptions[this.distributionStrategy].text
        },
        bundleHandinDatetime() {
            if (this.bundleDeadlineDate && this.bundleDeadlineTime) {
                const datetime = `${this.bundleDeadlineDate} ${this.bundleDeadlineTime}`
                const UTCDatetime = new Date(datetime).toISOString()
                return UTCDatetime
            }
            return ''
        },
        defaultSelectAllOrTarget() {
            if (
                this.isEditorOfDistributed &&
                this.targetSpaceType === this.spaceTypes.EDUCATION_SPACE &&
                this.selectedSpace &&
                this.selectedSpace.toString() === this.$route.params.sid.toString()
            ) {
                return this.distTargetType.SHARE_WITH_ALL_INSTANCES
            } else if (this.selectedSpaceAvailableTargetInstances.length) {
                return this.distTargetType.SHARE_WITH_SPECIFIC_INSTANCES
            }
            return null
        },
        disabledStepThree() {
            if (
                this.isStagingEmpty ||
                (this.isSpaceAdmin && this.selectedDistributionReason !== this.distributionSaveOptions.NO_BUNDLE && !this.validBundle) ||
                (this.bundleTab && this.stagingObjects.tables.length > 0)
            ) {
                return true
            }
            return false
        },
        getDistributionStrategyName() {
            return find(this.availableDistributionStrategyOptions, { backendCode: this.distributionStrategy }).text
        },
        getSelectedOrgName() {
            const org = find(this.userOrgs, { oid: this.selectedOrg })
            return org && org.long_id
        },
        getSelectedSharedwithInstanceLabels() {
            if (this.allOrSpecificInstances === this.distTargetType.SHARE_WITH_ALL_INSTANCES) {
                return this.shareWithAllLabel
            } else {
                return this.selectedInstances.length > 0 && this.selectedInstances[0].long_id
            }
        },
        spaceList() {
            const sortedSpaceList = sortArray(this.selectedOrgSpaces, 'long_id', 'ascending', false)
            if (this.spaceSearch) {
                const spacesListFilteredBySearch = sortedSpaceList.filter(space => space.long_id.toLowerCase().includes(this.spaceSearch.toLowerCase()))
                return spacesListFilteredBySearch.filter(space => !space.archival_timestamp)
            } else {
                return sortedSpaceList.filter(space => !space.archival_timestamp)
            }
        },
        orgList() {
            const sortedOrgList = sortArray(this.userOrgs, 'long_id', 'ascending', false)
            if (this.orgSearch) {
                const orgListFilteredBySearch = sortedOrgList.filter(org => org.long_id.toLowerCase().includes(this.orgSearch.toLowerCase()))
                return orgListFilteredBySearch
            } else {
                return sortedOrgList
            }
        },
        availableDistributionStrategyOptions() {
            return Object.values(distributionStrategyOptions).filter(item => item.backendCode !== distributionStrategyOptions.SKIP.backendCode)
        },
        shareWithAllLabel() {
            if (this.targetSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return 'Share with all students or groups in this course'
            }
            return 'Share with all instances'
        },
        shareWithOneOrSomeLabel() {
            if (this.targetSpaceType === this.spaceTypes.EDUCATION_SPACE && this.isDevelopment) {
                return 'Share with one instance'
            } else if (this.targetSpaceType === this.spaceTypes.EDUCATION_SPACE && !this.isDevelopment) {
                return 'Share with some instances'
            } else if (this.isDevelopment) {
                return 'Share with one instance'
            }
            return 'Share with specific instances'
        },
        targetSpaceType() {
            const space = this.selectedOrgSpaces.find(space => space.sid.toString() === this.selectedSpace.toString())
            return space ? space.space_type : null
        },
        selectedObjectTypes() {
            const objects = this.stagingObjects.files.concat(this.stagingObjects.tables).concat(this.stagingObjects.applications)
            const objectTypes = objects.map(object => object.fileType)
            return objectTypes
        },
        disableContinueInstanceSelection() {
            if (
                this.selectedSpace &&
                this.allOrSpecificInstances === this.distTargetType.SHARE_WITH_ALL_INSTANCES &&
                this.isEditorOfDistributed &&
                this.targetDistributedInstance.length
            ) {
                return false
            } else if (
                this.selectedSpace &&
                this.allOrSpecificInstances === this.distTargetType.SHARE_WITH_SPECIFIC_INSTANCES &&
                this.selectedSpaceAvailableTargetInstances.length &&
                this.selectedInstances.length
            ) {
                return false
            }
            return true
        },
        showInstancesTable() {
            return (
                this.selectedSpaceAvailableTargetInstances.length &&
                this.allOrSpecificInstances === this.distTargetType.SHARE_WITH_SPECIFIC_INSTANCES &&
                this.selectedSpace &&
                !this.fetchingSpaceData
            )
        },
        currentInstance() {
            return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
        },
        snapshotName() {
            const snapshot = this.$store.getters['snapshotStore/snapshotLongNameById'](this.$route.params.snid)
            return snapshot ? snapshot.toUpperCase() : ''
        },
        numberOfStagedObjects() {
            return this.stagingObjects.files.length + this.stagingObjects.tables.length + this.stagingObjects.applications.length
        },
        orderedStagedObjects() {
            const folders = this.stagingObjects.files.filter(file => file.type === this.nuvolosObjectTypes.FOLDER)
            const nonFolders = this.stagingObjects.files.filter(file => file.type !== this.nuvolosObjectTypes.FOLDER)
            const filesAndFolders = folders.concat(nonFolders)
            return {
                files: filesAndFolders,
                tables: this.stagingObjects.tables,
                applications: this.stagingObjects.applications
            }
        },
        isEditorOfDistributed() {
            const distInstance = this.selectedSpaceInstances.find(instance => isDistributedInstance(instance.long_id))
            if (distInstance && isEditorRole(distInstance.role)) {
                return true
            }
            return false
        },
        totalFileSize() {
            return this.orderedStagedObjects.files.reduce((totalSize, { size }) => totalSize + size, 0)
        },
        totalAppSize() {
            /* eslint-disable camelcase */
            return this.orderedStagedObjects.applications.reduce((totalSize, { lib_usage }) => totalSize + (lib_usage || 0), 0)
        }
    },
    methods: {
        async addRecentApps() {
            if (this.isTrialSpace) return
            await this.$store.dispatch('appStore/fetchRecentApps', this.$route.params.oid)
            if (
                this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE &&
                !this.isStagingEmpty &&
                !this.stagingObjects.applications.length &&
                this.isDevelopment
            ) {
                const availableApps = this.recentApps.filter(app => app.instance_id.toString() === this.$route.params.iid.toString())
                if (availableApps.length) {
                    this.addToStaging(availableApps)
                    this.applicationsAutoAdded = true
                }
            }
        },
        stageObjects(objectType) {
            let targetViewName
            const routeParams = {
                oid: this.$route.params.oid,
                sid: this.$route.params.sid,
                iid: this.$route.params.iid,
                snid: this.$route.params.snid
            }
            if (objectType === this.nuvolosObjectTypes.FILE) {
                targetViewName = 'snapshot-files'
                routeParams.localPath = this.currentFileLocalPath
                routeParams.fileArea = this.fileAreaType
                this.$store.commit('tourStore/stageTourStarted', true)
            } else if (objectType === this.nuvolosObjectTypes.TABLE) {
                targetViewName = 'snapshot-tables'
                this.$store.commit('tourStore/stageTableTourStarted', true)
            } else if (objectType === this.nuvolosObjectTypes.APPLICATION) {
                targetViewName = 'snapshot-applications'
                this.$store.commit('tourStore/stageAppTourStarted', true)
            }
            this.$router.push({
                name: targetViewName,
                params: routeParams
            })
        },
        moveToStepThree() {
            if (this.isSpaceAdmin && this.selectedDistributionReason !== this.distributionSaveOptions.NO_BUNDLE) {
                this.addBundle = true
            } else {
                this.addBundle = false
            }
            if (this.bundleTab) {
                this.getOrgData(this.$route.params.oid)
                this.stepperStep = 4
            } else {
                this.stepperStep = 3
                console.log('MOVED', this.isMasterInstance)
                this.allOrSpecificInstances = this.isMasterInstance
                    ? this.distTargetType.SHARE_WITH_ALL_INSTANCES
                    : this.distTargetType.SHARE_WITH_SPECIFIC_INSTANCES
                console.log('allOrSpecificInstances:', this.allOrSpecificInstances)
            }
        },
        setDefaultBundleName() {
            const now = new Date().toISOString()
            if (this.bundleTab) {
                this.bundleName = ''
            } else {
                this.bundleName = 'Distribution ' + this.$options.filters.dateTimeToHuman(now).replace(' / ', '_')
            }
        },
        createBundle() {
            this.creatingBundle = true
            const postBody = {
                visibility_type_name: this.selectedDistributionReason === this.distributionSaveOptions.PUBLIC ? 'VIEWERS' : 'SPACE_ADMINS',
                bundle_type_name: this.bundleTab ? this.distributionReasons.ASSIGNMENT : this.distributionReasons.MATERIAL_SHARING,
                long_id: this.bundleName,
                short_id: makeShortId(this.bundleName)
            }
            if (this.bundleTab) {
                postBody.description = this.assignmentDescription ? this.assignmentDescription : ''
                postBody.allow_handins_until = this.bundleHandinDatetime
            }
            if (this.stagingObjects.files.length) {
                postBody.source_fids = this.stagingObjects.files.map(file => file.fid)
            }
            if (this.stagingObjects.applications.length) {
                postBody.source_aoids = this.stagingObjects.applications.map(application => parseInt(application.aoid, 10))
            }
            if (this.stagingObjects.tables.length) {
                postBody.source_tids = this.stagingObjects.tables.map(table => parseInt(table.tid, 10))
            }
            this.$axios
                .post(`/snapshots/${this.$route.params.snid}/bundles`, postBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: this.bundleTab ? 'Assignment successfully created!' : 'Object list successfully saved',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.stepperStep = 2
                    this.$store.dispatch('spaceStore/fetchSpaceBundles', this.$route.params.sid)
                    this.clearStaging('all')
                    this.$store.commit('setBundleCreated', true)
                    this.$router.push({
                        name: 'snapshot-overview',
                        params: {
                            oid: this.$route.params.oid,
                            sid: this.$route.params.sid,
                            iid: this.$route.params.iid,
                            snid: this.$route.params.snid
                        }
                    })
                })
                .catch(error => {
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.reason &&
                        error.response.data.reason[0].includes('duplicate key value violates unique constraint')
                    ) {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Bundle name "${this.bundleName}" already in use. Please use another name for new bundles.`,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    } else {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Failed to create an object bundle of the distribued objects.`,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    }
                })
                .finally(() => {
                    this.creatingBundle = false
                })
        },
        setSelectedSpaceTableStatus() {
            const targetSpaceTableData = this.selectedOrgSpaces.find(space => space.sid.toString() === this.selectedSpace.toString())
            if (targetSpaceTableData) {
                this.snapshotTargetBeforeDistributing = !targetSpaceTableData.has_tables
                this.targetSpaceTablesAreEnabled = !!targetSpaceTableData.has_tables
            } else {
                this.snapshotTargetBeforeDistributing = true
                this.targetSpaceTablesAreEnabled = false
            }
            this.stepperStep = 4
        },
        addToStaging: function (data) {
            if (data.length) {
                const filesWithPaths = []
                let fileOrder = data.length
                data.forEach(file => {
                    const fileObject = file
                    fileObject.filePath = file.long_id
                    fileObject.fileType = this.nuvolosObjectTypes.APPLICATION
                    filesWithPaths.push(fileObject)
                    fileOrder = fileOrder - 1
                    if (fileOrder === 0) {
                        this.$store.dispatch('snapshotStore/updateStagingObjects', {
                            itemType: this.nuvolosObjectTypes.APPLICATION,
                            newItems: filesWithPaths,
                            updateMode: 'add'
                        })
                    }
                })
            }
        },
        getOrgData: function (oid) {
            this.selectedOrg = this.userOrgs.find(org => org.oid.toString() === oid.toString()).oid
            this.selectedSpaceInstances = []
            this.selectedSpace = ''
            this.fetchingOrgData = true
            this.$axios
                .get(`/orgs/${oid}/spaces?instance_roles=editor`)
                .then(response => {
                    this.selectedOrgSpaces = sortBy(response.data, 'long_id')
                    if (response.data.length && oid.toString() === this.$route.params.oid.toString()) {
                        this.getSpaceData(parseInt(this.$route.params.sid, 10))
                    }
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to fetch space data for the selected organization`,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.fetchingOrgData = false
                })
        },
        clearStaging: function (itemType) {
            this.$store.dispatch('snapshotStore/clearStagingObjects', itemType)
        },
        removeFromStaging: function (fileData, objectType) {
            this.$store.dispatch('snapshotStore/updateStagingObjects', {
                itemType: objectType,
                newItems: fileData,
                updateMode: 'remove'
            })
        },
        getSpaceData: function (sid) {
            // check if space is in same region
            this.selectedSpaceInstances = []
            this.selectedInstances = []
            if (this.selectedOrgSpaces.find(space => space.sid.toString() === sid.toString())) {
                this.selectedSpace = sid
                this.fetchingSpaceData = true
                this.$axios
                    .get(`spaces/${this.selectedSpace}/instances`)
                    .then(response => {
                        this.targetDistributedInstance = response.data.filter(instance => isDistributedInstance(instance.long_id))
                        this.selectedSpaceInstances = sortBy(response.data, 'iid')
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Failed to fetch instance data for the selected space`,
                            snackBarTimeout: 5000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.fetchingSpaceData = false
                    })
            } else {
                this.selectedSpace = undefined
            }
        },
        stageReadmeFile: function () {
            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE && !this.isStagingEmpty && this.isDevelopment) {
                const readmeFile = this.currentFiles.find(
                    file =>
                        file.short_id &&
                        file.short_id.toLowerCase() === 'readme.md' &&
                        file.local_path === '' &&
                        file.area === this.folderTypesLabels.WORKSPACE_FILES
                )
                if (readmeFile) {
                    readmeFile.fileType = this.userWorkAreas.WORKSPACE
                    readmeFile.filePath = readmeFile.short_id
                    this.$store.dispatch('snapshotStore/updateStagingObjects', {
                        itemType: this.nuvolosObjectTypes.FILE,
                        newItems: [readmeFile],
                        updateMode: 'add'
                    })
                }
            }
        },
        distribute: function () {
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            this.loading = true
            this.error = false
            let instanceIds
            if (this.allOrSpecificInstances === this.distTargetType.SHARE_WITH_ALL_INSTANCES) {
                instanceIds = [parseInt(this.targetDistributedInstance[0].iid, 10)]
            } else {
                instanceIds = this.selectedInstances.map(instance => parseInt(instance.iid, 10))
            }
            const putBody = {
                method: 'RSYNC',
                target_iids: instanceIds,
                auto_snapshot: this.snapshotTargetBeforeDistributing
            }
            if (this.selectedObjectTypes.includes(this.userWorkAreas.WORKSPACE)) {
                putBody.files_mode = this.customDistribution ? this.workspaceDistMode : this.distributionStrategy
            }
            if (this.selectedObjectTypes.includes(this.userWorkAreas.PERSONAL)) {
                putBody.home_mode = this.customDistribution ? this.personalFilesDistMode : this.distributionStrategy
            }
            if (this.selectedObjectTypes.includes(this.nuvolosObjectTypes.TABLE)) {
                putBody.tables_mode = this.customDistribution ? this.tablesDistMode : this.distributionStrategy
            }
            if (this.selectedObjectTypes.includes(this.nuvolosObjectTypes.APPLICATION)) {
                putBody.lib_mode = distributionStrategyOptions.MIRROR.backendCode
            }
            if (this.notifyTargetUsers) {
                putBody.notify_target_users = true
                if (this.notificationText) {
                    const textLines = this.notificationText.split('\n').map(function (text, index, array) {
                        if (index < array.length - 1) {
                            return text + '<br>'
                        } else {
                            return text
                        }
                    })
                    const htmlText = textLines.join('\n')
                    putBody.custom_email_message = htmlText
                }
            }
            if (this.stagingObjects.files.length) {
                const fids = this.stagingObjects.files.map(file => file.fid)
                putBody.source_fids = fids
            }
            if (this.stagingObjects.applications.length) {
                const aoids = this.stagingObjects.applications.map(application => parseInt(application.aoid, 10))
                putBody.source_aoids = aoids
            }
            if (this.stagingObjects.tables.length) {
                const tids = this.stagingObjects.tables.map(table => parseInt(table.tid, 10))
                putBody.source_tids = tids
            }
            const apiURL = `/snapshots/${this.$route.params.snid}/distribute_selected_async_v2`
            this.$axios
                .put(apiURL, putBody)
                .then(() => {
                    /* This whole block does nothing because of reroute */
                    let snackbarMessage
                    if (this.currentSpaceData && this.currentSpaceData.has_tables && this.currentSpaceData.has_tables) {
                        snackbarMessage = `Object distribution has been started. Because tables are activated, this operation might take several minutes. You will receive a summary email of the outcome. You can track progress <a href="${userTasksRoute}">here</a>`
                    } else {
                        snackbarMessage = `Object distribution has been started. You will receive a summary email of the outcome. You can track progress <a href="${userTasksRoute}">here</a>`
                    }
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: snackbarMessage,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'info'
                    })
                    /* This whole block does nothing because of reroute */
                    this.$store.dispatch('userStore/fetchUserTasks')
                    if (this.addBundle) {
                        this.createBundle()
                    } else {
                        this.clearStaging('all')
                        this.$router.push({
                            name: 'snapshot-files',
                            params: {
                                oid: this.$route.params.oid,
                                sid: this.$route.params.sid,
                                iid: this.$route.params.iid,
                                snid: this.$route.params.snid,
                                localPath: this.currentFileLocalPath,
                                fileArea: this.fileAreaType
                            }
                        })
                    }
                })
                .catch(() => {
                    this.error = true
                })
                .finally(() => {
                    this.loading = false
                })
        },
        humanFileSize: function (bytes, si) {
            return humanFileSize(bytes, si)
        }
    },
    mounted() {
        this.getOrgData(this.$route.params.oid)
        this.addRecentApps()
        this.setDefaultBundleName()
        if (this.defaultSelectAllOrTarget) {
            this.allOrSpecificInstances = this.defaultSelectAllOrTarget
        }
    },
    watch: {
        bundleTab: function (nextVal) {
            if (nextVal) {
                this.selectedDistributionReason = this.distributionSaveOptions.PUBLIC
            } else {
                this.selectedDistributionReason = this.distributionSaveOptions.ADMIN_ONLY
            }
            this.setDefaultBundleName()
            this.$refs.bundleNameInput.focus()
        },
        defaultSelectAllOrTarget: function (nextVal) {
            if (nextVal) {
                this.allOrSpecificInstances = nextVal
            }
        }
    }
}
</script>
